import React from 'react';
import ReactMarkdown from 'react-markdown';

const TooltipContent = ({ sentiment, count, percentage, summary }) => {
  const markdownContent = `
**${sentiment}**  
Count: ${count}  
${percentage}% of reviewers gave this rating  
Summary:  
${summary || 'No summary available'}
`;

  return (
    <div
      style={{
        maxWidth: '800px',
        padding: '10px',
        textalign: 'left',
        color: 'white',
        whiteSpace: 'normal', // Allow text to wrap to the next line
        wordWrap: 'break-word', // Break long words if needed
        overflowWrap: 'break-word' // Break text to the next line if it exceeds the container width
      }}
    >
      <ReactMarkdown>{markdownContent}</ReactMarkdown>
    </div>
  );
};

export default TooltipContent;
