import React from 'react'; 
import Chart from 'react-apexcharts';
import ReactDOMServer from 'react-dom/server';
import TooltipContent from './TooltipContent'; // Adjust the import path as necessary

const ApexPieChart = ({ sentiments }) => {
  const getSeriesData = (sentimentCategory) => {
    return Object.values(sentimentCategory).map((data) => data.totalNoOfReviews);
  };

  const getLabels = (sentimentCategory) => {
    return Object.keys(sentimentCategory);
  };

  const calculatePercentage = (count, total) => {
    return ((count / total) * 100).toFixed(2);
  };

  // Function to dynamically assign colors based on sentiment
  const getColorForSentiment = (sentiment) => {
    if (sentiment.toLowerCase() === 'great') return '#006400';  // Dark Green
    if (sentiment.toLowerCase() === 'good') return '#90EE90';   // Light Green
    if (sentiment.toLowerCase() === 'neutral') return '#FFD700'; // Yellow
    if (sentiment.toLowerCase() === 'bad') return '#FFA07A';    // Light Red
    if (sentiment.toLowerCase() === 'worst') return '#8B0000';  // Dark Red
    return '#d3d3d3';  // Default color (e.g., light gray) if sentiment doesn't match
  };

  const renderPieCharts = () => {
    return Object.keys(sentiments).map((category) => {
      const sentimentCategory = sentiments[category];
      const series = getSeriesData(sentimentCategory);
      const labels = getLabels(sentimentCategory);
      const totalRatings = series.reduce((acc, val) => acc + val, 0);

      // Check if there is any data available for the current category
      if (totalRatings === 0) return null;

      // Dynamically map colors to labels (sentiments)
      const colors = labels.map((label) => getColorForSentiment(label));

      const options = {
        chart: {
          type: 'pie',
        },
        labels: labels,
        tooltip: {
          custom: function({ series, seriesIndex, dataPointIndex }) {
            const sentiment = labels[seriesIndex];
            const data = sentimentCategory[sentiment];
            const count = series[seriesIndex];
            const percentage = calculatePercentage(count, totalRatings);

            // Render React component to HTML string
            const tooltipHtml = ReactDOMServer.renderToStaticMarkup(
              <TooltipContent
                sentiment={sentiment}
                count={count}
                percentage={percentage}
                summary={data ? data.summary : ''}
              />
            );

            return tooltipHtml;
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center',
        },
        plotOptions: {
          pie: {
            expandOnClick: true,
            dataLabels: {
              style: {
                fontSize: '16px',
                fontWeight: 'bold',
              },
            },
          },
        },
        fill: {
          colors: colors,  // Use dynamically assigned colors
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['#fff'],
        },
        states: {
          hover: {
            filter: {
              type: 'none',
            },
            brightness: 0.8,
          },
          active: {
            filter: {
              type: 'none',
            },
            style: {
              fontSize: '20px',
              fontWeight: 'bold',
              shadow: '0 4px 15px rgba(0, 0, 0, 0.8)',
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: '100%',
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      };

      return (
        <div
          key={category}
          className="chart-container"
          style={{
            marginTop: '50px',
            marginBottom: '50px',
            textAlign: 'center',
            backgroundColor: 'white',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            padding: '20px',
            width: '100%',
            maxWidth: '1400px',
          }}
        >
          <h3>{category}</h3>
          <Chart
            options={options}
            series={series}
            type="pie"
            width="100%"
            height="400"
          />
        </div>
      );
    }).filter(chart => chart !== null); // Remove null entries from the rendered charts
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {renderPieCharts()}
    </div>
  );
};

export default ApexPieChart;


// import React from 'react';
// import Chart from 'react-apexcharts';

// const ApexPieChart = ({ sentiments }) => {
//   // Directly get the values and labels from the sentiments object
//   const getSeriesData = (sentiments) => {
//     return Object.values(sentiments); // Get the numerical values
//   };

//   const getLabels = (sentiments) => {
//     return Object.keys(sentiments); // Get the categories (e.g., 'Food', 'Location')
//   };

//   // Function to calculate the percentage
//   const calculatePercentage = (count, total) => {
//     return ((count / total) * 100).toFixed(2);
//   };

//   const renderPieCharts = () => {
//     const series = getSeriesData(sentiments);
//     const labels = getLabels(sentiments);
//     const totalRatings = series.reduce((acc, val) => acc + val, 0);

//     const options = {
//       chart: {
//         type: 'pie',
//       },
//       labels: labels,
//       tooltip: {
//         custom: function({ series, seriesIndex }) {
//           const sentiment = labels[seriesIndex];
//           const count = series[seriesIndex];
//           const percentage = calculatePercentage(count, totalRatings);

//           return `<div style="width:200px;">
//             <strong>${sentiment}</strong><br/>
//             Count: ${count}<br/>
//             ${percentage}% of reviews</div>`;
//         },
//       },
//       legend: {
//         position: 'top',
//         horizontalAlign: 'center',
//       },
//       plotOptions: {
//         pie: {
//           expandOnClick: true,
//           dataLabels: {
//             style: {
//               fontSize: '16px',
//               fontWeight: 'bold',
//             },
//           },
//         },
//       },
//       fill: {
//         type: 'gradient',
//         gradient: {
//           shade: 'dark',
//           type: 'vertical',
//           gradientToColors: ['#ABE5A1', '#F9D423', '#F64747'],
//           stops: [0, 100],
//         },
//       },
//       stroke: {
//         show: true,
//         width: 2,
//         colors: ['#fff'],
//       },
//       states: {
//         hover: {
//           filter: {
//             type: 'none',
//           },
//           brightness: 0.8,
//         },
//         active: {
//           filter: {
//             type: 'none',
//           },
//           style: {
//             fontSize: '20px',
//             fontWeight: 'bold',
//             shadow: '0 4px 15px rgba(0, 0, 0, 0.8)',
//           },
//         },
//       },
//       responsive: [
//         {
//           breakpoint: 480,
//           options: {
//             chart: {
//               width: '100%',
//             },
//             legend: {
//               position: 'bottom',
//             },
//           },
//         },
//       ],
//     };

//     return (
//       <div
//         key="sentimentChart"
//         className="chart-container"
//         style={{
//           marginTop: '50px',
//           marginBottom: '50px',
//           textAlign: 'center',
//           backgroundColor: 'white',
//           borderRadius: '10px',
//           boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//           padding: '20px',
//           width: '100%',
//           maxWidth: '1400px',
//         }}
//       >
//         <h3>Sentiment Distribution</h3>
//         <Chart
//           options={options}
//           series={series}
//           type="pie"
//           width="100%"
//           height="400"
//         />
//       </div>
//     );
//   };

//   return (
//     <div
//       style={{
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'center',
//         alignItems: 'center',
//       }}
//     >
//       {renderPieCharts()}
//     </div>
//   );
// };

// export default ApexPieChart;
