import { Box, Button, Dialog, IconButton, Typography, useTheme } from "@mui/material"; 
import { useState } from "react";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useDispatch, useSelector } from "react-redux";
import { onDark, offDark } from "../App/features/darkMode";
import { useRequestComparisionMutation, useRequestSurveyMutation } from "../Services/ApiServices";
import FetchLoading from "./Messages/FetchLoading";

const Topbar = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [surveyMessage, setSurveyMessage] = useState(""); 
  const [message, setMessage] = useState(""); // State to hold the message
  const [sendCompRequest, { isLoading: compLoading }] = useRequestComparisionMutation();
  const [sendSurvRequest, { isLoading: survLoading }] = useRequestSurveyMutation();

  const dark = useSelector((state) => state.darkMode.dark);

  const handleDarkMode = () => {
    if (dark) {
      dispatch(offDark());
    } else {
      dispatch(onDark());
    }
  };

  const sendrequestSurvey = async () => {
    setOpen(true); // Open the modal
    setMessage(""); // Reset message before sending request
    
    try {
      // Send the survey request with the message
      const response = await sendSurvRequest({ message: surveyMessage }).unwrap();
    
      // If the API call is successful
      setMessage(response.data.message); // Display success message
    } catch (error) {
      // Handle the error and display a message in the modal
      setMessage("An error occurred while sending the survey request.");
    }
  };
  

  const sendrequestComp = async () => {
    setOpen(true); // Open the modal
    setMessage(""); // Reset message before sending request

    try {
      const response = await sendCompRequest().unwrap(); // Use unwrap to handle the response directly
      setMessage(response.data.message); // Display success or error message based on the response
    } catch (error) {
      // Handle the error and display a message in the modal
      setMessage("An error occurred while sending the comparison request.");
    }
  };

  const handleClosing = () => {
    setOpen(false);
    setMessage(""); // Reset message on close
    setSurveyMessage(""); // Reset the survey message on close
  };

  const handleCloseAfterResponse = () => {
    setOpen(false); // Close the dialog
    setMessage(""); // Reset the message
    setSurveyMessage(""); // Reset the survey message
  };

  return (
    <div style={{ display: "flex", width: "100%", padding: "2%", justifyContent: "space-between", backgroundColor: "default" }}>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <Box
          display="flex"
          backgroundColor={theme.palette.background.main}
          borderRadius="3px"
          sx={{ width: "400px", height: "200px", display: "flex", justifyContent: "center", flexDirection: "column", padding: "20px" }}
        >
          {message ? (
            // Display only the message after sending the request
            <>
              <Typography variant="h6" sx={{ marginBottom: "10px" }}>{message}</Typography>
              <Button
                variant="outlined"
                sx={{ width: "100%", marginTop: "10px" }}
                onClick={handleCloseAfterResponse} // Close after response
              >
                Close
              </Button>
            </>
          ) : (
            <>
              <InputBase
                placeholder="Enter survey message"
                fullWidth
                multiline
                rows={3}
                value={surveyMessage}
                onChange={(e) => setSurveyMessage(e.target.value)}
                sx={{ backgroundColor: theme.palette.background.paper, padding: "10px", borderRadius: "4px", marginBottom: "10px" }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={sendrequestSurvey}
                disabled={survLoading || !surveyMessage}
                sx={{ width: "100%" }}
              >
                {survLoading ? <FetchLoading /> : "Send Survey"}
              </Button>
              <Button
                variant="outlined"
                sx={{ width: "100%", marginTop: "10px" }}
                onClick={handleClosing}
              >
                Cancel
              </Button>
            </>
          )}
        </Box>
      </Dialog>

      <Box display="flex" backgroundColor={theme.palette.background.main} borderRadius="3px"></Box>
      <Box display="flex">
        <Button style={{ margin: "2%" }} color="warning" onClick={sendrequestComp} variant="outlined">Comparison</Button>
        <Button style={{ margin: "2%" }} color="warning" onClick={() => setOpen(true)} variant="outlined">Survey</Button>

        <IconButton onClick={handleDarkMode}>
          {dark ? <LightModeOutlinedIcon color="primary" /> : <DarkModeOutlinedIcon color="primary" />}
        </IconButton>
        <IconButton color="primary">
          <NotificationsOutlinedIcon color="primary" />
        </IconButton>
        <IconButton color="primary">
          <PersonOutlinedIcon color="primary" />
        </IconButton>
      </Box>
    </div>
  );
};

export default Topbar;
