import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios"; // Import axios
import LoadingSpinner from "../Messages/LoadingSpinner";
import ChartPage from './LineGraph'; // Assuming LineGraph can handle both line and donut charts
import ApexDonutChartWithDetails from './ApexDonutChartWithDetails'; // Import the donut chart component

export default function NexusData() {
  const hotelId = useSelector(state => state.detail.id);
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState({ error: false, message: "" });
  const [nexusData, setNexusData] = useState(null);
   
  const getToken = () =>{
    let token = ""
    if(sessionStorage.getItem("token")){
      token = sessionStorage.getItem("token")
    }
    return token
  }
  
  
  const getHeaders = () =>{
      const commonHeaders = {
        "Content-Type": 'application/json',
        Accept: '*/*',
        credentials: true,
        Authorization: getToken(),
        Connection: 'Keep-alive',
        'Accept-Encoding': 'gzip, deflate, br',
  
      }; 
      return commonHeaders
  }
  const fetchNexusData = async () => {
    if (hotelId) {
      setLoading(true);
      try {
        const headers = { ...getHeaders() }; // Add headers here if needed
        const response = await axios.get(`http://104.131.2.125/api/reviewAnalysis/analysis/detail/nexus/${hotelId}`, { headers });
        
        if (response.data) {
          setNexusData(response.data.data); // Set data to state
        } else {
          setErr({ error: true, message: "No data received" });
        }
      } catch (error) {
        setErr({ error: true, message: error.message });
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setErr({ error: true, message: "No hotelId found" });
    }
  };

  useEffect(() => {
    fetchNexusData();
  }, [hotelId]);

  // Extract data for charts
  const reviewsForLineGraph = nexusData?.reviewsForLineGraph || [];
  const sentiments = nexusData?.sentiments || {};
  const sentimentsForLineGraph = nexusData?.sentimentsForLineGraph || {};

  // Prepare dynamic data for the donut chart
  const sentimentData = Object.keys(sentiments).reduce((acc, key) => {
    acc[key] = sentiments[key] || 0;
    return acc;
  }, {});

  return (
    <Box width={"100%"}>
      {loading ? (
        <LoadingSpinner />
      ) : err.error ? (
        <div>Error: {err.message}</div>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} xl={12} lg={12} md={12}>
            <ChartPage reviewsForLineGraph={reviewsForLineGraph} sentimentsForLineGraph={sentimentsForLineGraph} />
          </Grid>
          <Grid item xs={12} xl={12} lg={12} md={12}>
            <ApexDonutChartWithDetails sentiments={sentimentData} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
