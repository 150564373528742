import { useTheme } from "@emotion/react";
import { Add, AddReaction, Checklist, ChecklistOutlined, Home, House, ManageAccounts, Map, People, PeopleOutline, QrCode2, ViewList, Visibility, WhereToVote } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CompareIcon from '@mui/icons-material/Compare';

export default function MenuGenerator() {
    const theme = useTheme()
    const role = useSelector(state=>state.user.role)
   
    const nav = useNavigate()
    const textclr = theme.palette.primary.main
    const generator = () =>{
        if(role === "SysAdmin") {
            return <Menu>
                        <MenuItem onClick={()=>{nav("/superAdminHome")}} icon={<Home color="primary"/>}><Typography  color= {textclr} variant="body2">Home</Typography></MenuItem>
                        <MenuItem onClick={()=>{nav("/viewTenants")}} icon={<People color="primary"/>} ><Typography color= {textclr}  variant="body2">Tenants</Typography></MenuItem>
                        <MenuItem onClick={()=>{nav("/formList")}} icon={<ChecklistOutlined color="primary"/>}  ><Typography color= {textclr}  variant="body2">Forms</Typography></MenuItem>                     
                        <MenuItem onClick={()=>{nav("/tenanttype")}} icon={<ViewList color="primary"/>}  ><Typography color= {textclr}  variant="body2">Tenant Type</Typography></MenuItem>                     
                        <MenuItem onClick={()=>{nav("/sentiments")}} icon={<AddReaction color="primary"/>}  ><Typography color= {textclr}  variant="body2">Sentiments</Typography></MenuItem>                     
                        <MenuItem onClick={()=>{nav("/manageTenantType")}} icon={<ManageAccounts color="primary"/>} ><Typography color= {textclr}  variant="body2">Type Management</Typography></MenuItem>
                    </Menu>
        }
        if(role === "TenantAdmin") {
            return <Menu>
                        <MenuItem onClick={()=>{nav("/tadminHome")}} icon={<Home color="primary"/>}><Typography  color= {textclr} variant="body2">Home</Typography></MenuItem>
                        <MenuItem onClick={()=>{nav("/viewAdmins")}} icon={<People color="primary"/>} ><Typography color= {textclr}  variant="body2">Admins</Typography></MenuItem>                     
                        <MenuItem onClick={()=>{nav("/viewreviewforms")}} icon={<ChecklistOutlined  color="primary"/>} ><Typography color= {textclr}  variant="body2">Forms</Typography></MenuItem>                     
                    </Menu>
        }
        if(role === "Admin") {
            return <Menu>
                        <MenuItem onClick={()=>{nav("/adminHome")}} icon={<Home color="primary"/>}><Typography  color= {textclr} variant="body2">Home</Typography></MenuItem>
                        <MenuItem onClick={()=>{nav("/viewRegion")}} icon={<Map color="primary"/>} ><Typography color= {textclr}  variant="body2">Region</Typography></MenuItem>
                        <MenuItem onClick={()=>{nav("/viewHeads")}} icon={<People color="primary"/>}  ><Typography color= {textclr}  variant="body2">Heads</Typography></MenuItem>            
                        <MenuItem onClick={()=>{nav("/reviews")}} icon={<WhereToVote color="primary"/>} ><Typography color= {textclr}  variant="body2">Hotel Reviews</Typography></MenuItem>  
                        <MenuItem onClick={()=>{nav("/CompareRegions")}} icon={<CompareIcon color="primary"/>} ><Typography color= {textclr}  variant="body2">Compare region</Typography></MenuItem>            
                    </Menu>
        }
        else if(role === "Head") {
            return <Menu>
                        <MenuItem onClick={()=>{nav("/headHome")}} icon={<Home color="primary"/>}><Typography  color= {textclr} variant="body2">Home</Typography></MenuItem>
                        <MenuItem onClick={()=>{nav("/viewManagers")}} icon={<People color="primary"/>} ><Typography color= {textclr}  variant="body2">Managers</Typography></MenuItem>
                        <MenuItem onClick={()=>{nav("/viewBranches")}} icon={<WhereToVote color="primary"/>} ><Typography color= {textclr}  variant="body2">Branches</Typography></MenuItem>            
                        <MenuItem onClick={()=>{nav("/comparebranch")}} icon={<CompareIcon color="primary"/>} ><Typography color= {textclr}  variant="body2">Compare Branch</Typography></MenuItem>            
                    </Menu>
        }
        else if(role === "Manager") {
            return <Menu>
                <MenuItem onClick={()=>{nav("/managerHome")}} icon={<Home color="primary"/>}><Typography color= {textclr}  variant="body2">Home</Typography></MenuItem>
                <MenuItem onClick={()=>{nav("/generateLink")}} icon={<QrCode2 color="primary"/>}><Typography  color= {textclr} variant="body2">Generate Link</Typography></MenuItem>
            </Menu>
        }
    }
    return (
        <>
            {generator()}
        </>
    )
}