import { configureStore, combineReducers} from "@reduxjs/toolkit"
import sessionSlice from "./features/sessionSlice"
import { apiSlice } from "../Services/ApiServices"
import preferenceSlice from "./features/preferenceSlice"
import placeSlice from "./features/placeSlice"
import userSlice from "./features/userSlice"
import regionSlice from "./features/regionSlice"
import formSlice from "./features/formSlice"
import adminsSlice from "./features/adminsSlice"
import hotelSlice from "./features/hotelDetail"
import tokenSlice from "./features/tokenSlice"
import managerSlice from "./features/managerSlice"
import headsSlice from "./features/headsSlice"
import localFormSlice from "./features/localFormSlice"
import branchSlice from "./features/branchSlice"
import dashboadSlice from "./features/dashboadSlice"
import clientReview from "./features/clientReview"
import adRegionSlice from "./features/adRegionSlice"
import adID from "./features/adID"
import darkMode from "./features/darkMode"
import tenantTypeSlice from "./features/tenatType"
import sentimentSlice from "./features/sentimentSlice"
import tenantSlice from "./features/tenantsSlice"
import formsListSlice from "./features/formsListSlice"
import tempIDSlice from "./features/tempFormID"
import googleData from "./features/googleDataSlice"
import nexusData from "./features/nexusDataSlice"
import openAiData from "./features/openAiSlice"

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('state', serializedState);
  } catch (err) {
    console.log("some Error", err);
  }
};

// loadState.js

export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const persistedState = loadState();



const rootReducers = combineReducers({
        session: sessionSlice,
        dashboard: dashboadSlice,
        client: clientReview,
        AdId: adID,
        darkMode: darkMode,
        adRegion: adRegionSlice,
        preference: preferenceSlice,
        place: placeSlice,
        region: regionSlice,
        detail: hotelSlice,
        admins: adminsSlice,
        form: formSlice,
        user: userSlice,
        managers: managerSlice,
        heads: headsSlice,
        branches: branchSlice,
        lcForm: localFormSlice,
        tenantType: tenantTypeSlice,
        token: tokenSlice,
        tenants: tenantSlice,
        tempId: tempIDSlice,
        senti: sentimentSlice,
        formsList: formsListSlice,
        googleData: googleData,
        nexusData: nexusData,
        openAiData: openAiData,
        [apiSlice.reducerPath]: apiSlice.reducer,
    })
    

const store = configureStore(
    {
        reducer: rootReducers, 
        preloadedState: persistedState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(apiSlice.middleware),
             immutableCheck: false, // Disable immutable state invariant middleware
             serializableCheck: false,
    }
)

store.subscribe(() => {
  saveState(store.getState()); // Save state to localStorage on every Redux store update
});

export default store