import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography, CircularProgress, TextField, Button, MenuItem, Checkbox, FormControlLabel, FormGroup, Rating,Container } from '@mui/material';
import MainSideBar from "../../Components/SideBar";
import { useParams } from 'react-router-dom';
import { useGetFormsListByIdQuery } from '../../Services/ApiServices';
import { MessageGenerator, type } from '../../Components/Messages/MesageGenerator';
import { useTheme } from '@mui/material/styles'; // To use theme colors

const ViewReviewFormsDetail = () => {
    const theme = useTheme(); // Access the Material-UI theme
    const { formsId } = useParams();
    const { data, isLoading, isError, error } = useGetFormsListByIdQuery(formsId);
    const [err, setErr] = useState({ error: false, message: "" });
    const [formData, setFormData] = useState({});

    useEffect(() => {
        if (isError) {
            setErr({ error: true, message: error?.data?.message || "An error occurred while fetching the form." });
        }
    }, [isError, error]);

    // Handle form input changes
    const handleInputChange = (key, value) => {
        setFormData(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    // Handle checkbox input changes
    const handleCheckboxChange = (key, value) => {
        setFormData(prevState => ({
            ...prevState,
            [key]: {
                ...prevState[key],
                [value]: !prevState[key]?.[value] || false
            }
        }));
    };

    // Check if data exists and contains review_form
    const reviewForm = data?.data?.review_form || [];

    // Styles
    const paperStyle = {
        padding: 40,
        margin: "0 auto",
        border: `3px solid ${theme.palette.primary.main}`, 
        borderRadius: '16px', 
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', // Adding shadow to the form
        maxWidth: '800px', 
    };

    const fieldStyle = {
        padding: '16px',
        borderRadius: '8px', 
        marginBottom: '16px',
    };

    return (
        <MainSideBar>
            <Container sx={{ paddingTop: "20px", position: 'relative' }}>
          <Button
  variant="outlined"
  onClick={() => { window.history.go(-1); return false; }}
  style={{
    width: '100px',
    padding: '8px 4px',
    position: 'relative',
    top: '40px', // Adjust this value for vertical positioning if needed
    left: '20px',
    marginBottom: '70px', // Increase marginBottom for more space
  }}
>
  Go Back
</Button>

            <Paper style={paperStyle} elevation={6}>
            
                <Grid container gap={3} alignContent="center">
                
                    <Grid item xs={12}>
                        <Typography variant="h4" color="textPrimary" align="center">Review Form Details</Typography>
                    </Grid>

                    {isLoading && (
                        <Grid item xs={12} align="center">
                            <CircularProgress />
                        </Grid>
                    )}

                    {err.error && (
                        <Grid item xs={12} align="center">
                            <MessageGenerator type={type.err} message={err.message} />
                        </Grid>
                    )}

                    {/* Dynamically render fields */}
                    {reviewForm.map((field) => {
                        if (field.will_render) {
                            switch (field.input_type) {
                                case 'STRING': // Regular text fields
                                    return (
                                        <Grid item xs={12} key={field.id} style={fieldStyle}>
                                            <TextField
                                                label={field.title}
                                                variant="outlined"
                                                fullWidth
                                                value={formData[field.title] || ''}
                                                onChange={(e) => handleInputChange(field.title, e.target.value)}
                                                required={field.is_required}
                                            />
                                        </Grid>
                                    );
                                
                                case 'paragraph': // TextArea (for long texts)
                                    return (
                                        <Grid item xs={12} key={field.id} style={fieldStyle}>
                                            <TextField
                                                label={field.title}
                                                variant="outlined"
                                                multiline
                                                rows={4}
                                                fullWidth
                                                value={formData[field.title] || ''}
                                                onChange={(e) => handleInputChange(field.title, e.target.value)}
                                                required={field.is_required}
                                            />
                                        </Grid>
                                    );

                                case 'DropDown': // Dropdown field
                                    return (
                                        <Grid item xs={12} key={field.id} style={fieldStyle}>
                                            <TextField
                                                select
                                                label={field.title}
                                                variant="outlined"
                                                fullWidth
                                                value={formData[field.title] || ''}
                                                onChange={(e) => handleInputChange(field.title, e.target.value)}
                                                required={field.is_required}
                                            >
                                                {field.options.split(',').map(option => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    );

                                case 'CheckBoxes': // Checkboxes
                                    return (
                                        <Grid item xs={12} key={field.id} style={fieldStyle}>
                                            <FormGroup>
                                                {field.options.split(',').map(option => (
                                                    <FormControlLabel
                                                        key={option}
                                                        control={
                                                            <Checkbox
                                                                checked={formData[field.title]?.[option] || false}
                                                                onChange={() => handleCheckboxChange(field.title, option)}
                                                            />
                                                        }
                                                        label={option}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </Grid>
                                    );

                                case 'Star Rating': // Rating component
                                    return (
                                        <Grid item xs={12} key={field.id} style={fieldStyle}>
                                            <Typography component="legend">{field.title}</Typography>
                                            <Rating
                                                name={field.title}
                                                value={formData[field.title] || 0}
                                                onChange={(event, newValue) => handleInputChange(field.title, newValue)}
                                                precision={0.5} // Allows half-star ratings
                                                size="large"
                                            />
                                        </Grid>
                                    );

                                default:
                                    return null;
                            }
                        }
                        return null;
                    })}

                    {/* Submit Button */}
                    <Grid item xs={12} align={'center'}>
                        <Button variant='contained' color='primary' onClick={() => console.log(formData)}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            </Container>
        </MainSideBar>
    );
};

export default ViewReviewFormsDetail;
