import React from "react"; 
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Container,
  Button,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MainSideBar from "../../Components/SideBar";
import { useGetFormsListQuery } from "../../Services/ApiServices"; // Assuming this is a Redux query hook
import { useNavigate } from "react-router-dom"; // Import useNavigate

const ViewReviewForms = () => {
  const theme = useTheme(); // Access the theme to use primary color
  const navigate = useNavigate(); // Initialize useNavigate

  // Fetching data using Redux API services hook
  const { data, isLoading, error, refetch } = useGetFormsListQuery();

  // If the data is loading, show a loading spinner
  if (isLoading) {
    return (
      <MainSideBar>
        <Container sx={{ paddingTop: "20px", textAlign: "center" }}>
          <CircularProgress />
          <Typography variant="h6" sx={{ marginTop: 2 }}>
            Loading review forms...
          </Typography>
        </Container>
      </MainSideBar>
    );
  }

  // Handle error state and log the error to console
  if (error) {
    console.error("Error fetching review forms:", error);
    return (
      <MainSideBar>
        <Container sx={{ paddingTop: "20px", textAlign: "center" }}>
          <Typography variant="h6" color="error">
            Error loading review forms. Please try again later.
          </Typography>
          {/* Refetch Button */}
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: 2 }}
            onClick={() => refetch()} // Trigger refetch when the button is clicked
          >
            Retry
          </Button>
        </Container>
      </MainSideBar>
    );
  }

  // Ensure `data` and `data.data.review_forms` are defined before rendering the cards
  const reviewForms = data?.data?.review_forms || []; // Accessing the review_forms from the data structure

  return (
    <MainSideBar>
      <Container sx={{ paddingTop: "20px", position: 'relative' }}> {/* Set container to relative */}
        {/* Go Back Button at the top left */}
        <Button
          variant="outlined"
          onClick={() => { window.history.go(-1); return false; }}
          style={{
            width: '100px', // Set a fixed width for better visibility
            padding: '8px 4px', // Adjust padding for size
            position: 'relative', // Position the button absolutely within the container
            top: '40px', // Increased top position for more space
            left: '20px', // Adjust the left position
            marginBottom:'50px'
          }}
        >
          Go Back
        </Button>
        <Grid container spacing={3}>
          {reviewForms.length > 0 ? (
            reviewForms.map((form) => (
              <Grid item xs={12} sm={6} md={4} key={form.id}>
                <Card>
                  <CardContent>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{ color: theme.palette.primary.main }} // Applying primary color to title
                    >
                      {form.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
                      Price: ${form.price}
                    </Typography>
                    {/* Action Buttons */}
                    <Button
                      variant="outlined"
                      sx={{ marginRight: 1 }}
                      onClick={() => navigate(`/ViewReviewFormsDetail/${form.id}`)} // Navigate to details page
                    >
                      View
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => alert(`Buying ${form.title}`)}
                    >
                      Buy
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography variant="h6">No review forms available.</Typography>
          )}
        </Grid>
      </Container>
    </MainSideBar>
  );
};

export default ViewReviewForms;
